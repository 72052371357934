import VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinComponent from '../components/VerticalAnchorsMenu/viewer/skinComps/VerticalAnchorsMenuLinkedNoTextSkin/VerticalAnchorsMenuLinkedNoTextSkin.skin';
import VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinController from '../components/VerticalAnchorsMenu/viewer/VerticalAnchorsMenu.controller';


const VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin = {
  component: VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinComponent,
  controller: VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinController
};


export const components = {
  ['VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin']: VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin
};

